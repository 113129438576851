import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Layout from '../components/Layout';

import { contentfulRenderOptions } from 'utilities/functions.tsx';

import pageStyles from '../pages/pages.module.css';

export default ({ data }) => {
  const page = get(data, 'contentfulStaticPage');
  const { siteMetadata } = data.site;

  return (
    <Layout>
      <Helmet>
        <title>{page.title + ' - ' + siteMetadata.title}</title>
        <link rel="canonical" href={siteMetadata.baseUrl + page.slug}/>
        {page.description && (
          <meta name="description" content={page.description}/>
        )}
        {(page.slug === '/privacy' || page.slug === '/terms') && (
          <meta name="robots" content="noindex,follow"/>
        )}
      </Helmet>
      <Grid container>
        <Grid item xs={10} md={8} className={pageStyles.container} style={{ margin: '0 auto' }}>
          <Typography variant="h3" style={{marginBottom: '25px'}}>{page.title}</Typography>
          {page.showUpdatedDate && (
            <Typography variant="body2">Last modified: {page.updatedAt}</Typography>
          )}
          <Typography
            variant="body2"
            style={{ fontSize: '1rem' }}
            component="div"
          >
            {documentToReactComponents(page.copy.json, contentfulRenderOptions)}
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query StaticPageBySlug($slug: String!) {
    contentfulStaticPage(slug: { eq: $slug }) {
      title
      slug
      description
      updatedAt(formatString: "MMMM Do, YYYY")
      copy {
        json
      }
      showUpdatedDate
    }
    site {
      siteMetadata {
        title
        baseUrl
      }
    }
  }
`;